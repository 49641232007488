.icon {
    font-family: 'eeeban' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html{
    overflow: hidden;
}

@font-face {
    font-family: "Rubrik";
    src: url("../assets/fonts/Rubrik.otf"),
        url("../assets/fonts/RubrikBold.otf"),
        url("../assets/fonts/RubrikMedium.otf"),
        url("../assets/fonts/RubrikSemiBold.otf");
}